export default [
  { key: 'id', label: 'Id', selected: true },
  {
    key: 'family_id', label: 'Family ID', selected: true, sortable: true,
  },
  {
    key: 'first_name', label: 'First Name', selected: true, sortable: true,
  },
  {
    key: 'last_name', label: 'Last Name', selected: true, sortable: true,
  },
  {
    key: 'full_name', label: 'Full Name', selected: true, sortable: true,
  },
  {
    key: 'last_login', label: 'Last Login', selected: true, sortable: true,
  },
  {
    key: 'registration_date', label: 'Registration Date', name: 'created_at', selected: true, sortable: true,
  },
  {
    key: 'is_primary', label: 'Primary', selected: true, sortable: false,
  },
  {
    key: 'user_parent_children', label: 'Children', selected: true, sortable: false,
  },
  {
    key: 'user_parent_status', label: 'Status', selected: true, sortable: false,
  },
  {
    key: 'total_balance', label: 'Balance', selected: true, sortable: true,
  },
  {
    key: 'relationship', label: 'Relationship', selected: true, sortable: false,
  },
  {
    key: 'email', label: 'Email', selected: true, sortable: true,
  },
  {
    key: 'phone', label: 'Phone', selected: true, sortable: true,
  },
  {
    key: 'actions', label: 'Actions', selected: true, sortable: false,
  },
]
